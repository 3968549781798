.iconWrap {
  position: relative;
  width: 328px;
  height: 248.5px;

  @media screen and (max-width: 1440px) {

    // svg {
    //   width: 100px;
    //   h100
    // }
  }
  
  @media screen and (max-width: 1024px) {
    
    &:nth-child(2) {
      margin: 0 128px;
    }
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {
    &:nth-child(2) {
      margin: 0 80px;
    }
  }

}

.iconText {
  position: absolute;
  left: 0;
  bottom: -220px;

  @media screen and (max-width: 1440px) {
    bottom: -120px;
  }
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}

.iconTitle {
  font-weight: 400;
  font-size: 3.75vw;
  line-height: 4.43vw;
  text-transform: uppercase;

  @media screen and (max-width: 1440px) {
    font-size: 4.03vw;
    line-height: 4.79vw;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 40px;
    line-height: 47px;
  }
}

.iconDescription {
  font-weight: 300;
  font-size: 1.25vw;
  line-height: 1.46vw;

  @media screen and (max-width: 1440px) {
    font-size: 1.39vw;
    line-height: 1.67vw;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 19px;
  }
}