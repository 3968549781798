.letter {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  position: relative;
}


.svg {
  display: block;
  font: 20.5em 'Mazzard';
  max-width: 960px;
  width: 100%;
  max-height: 300px;
  height: auto;
  margin: 0 auto;
}

.textCopy {
  fill: none;
  stroke: white;
  stroke-dasharray: 6% 29%;
  stroke-width: 5px;
  stroke-dashoffset: 0%;
  -webkit-animation: stroke-offset 5.5s infinite linear;
          animation: stroke-offset 5.5s infinite linear;
}

.textCopy:nth-child(1) {
  stroke: #282728;
  -webkit-animation-delay: -1;
          animation-delay: -1;
}

.textCopy:nth-child(2) {
  stroke: #282728;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}

.textCopy:nth-child(3) {
  stroke: #282728;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
}

.textCopy:nth-child(4) {
  stroke: #282728;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
}

.textCopy:nth-child(5) {
  stroke: #282728;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
}

@-webkit-keyframes stroke-offset {
  100% {
    stroke-dashoffset: -35%;
  }
}

@keyframes stroke-offset {
  100% {
    stroke-dashoffset: -35%;
  }
}

.contentWrap {
  display: flex;
  align-items: center;
}

.content1,
.content2,
.content3 {
  height: 160px;
  overflow: hidden;
  font-family: 'Mazzard', sans-serif;
  font-size: 1.5vw;
  line-height: 1.8vw;

  &__container {
    font-weight: 600;
    overflow: hidden;
    width: 340px;
    height: 40px;
    text-align: center;

    @media screen and (max-width: 1024px) {

      width: 200px;
    }    
    
    @media screen and (max-width: 768px) {
      width: 80px;
    }

    &:after,
    &:before {
      position: absolute;
      top: 0;
      color: #16a085;
      font-size: 42px;
      line-height: 40px;
      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &__text {
      display: inline;
      float: left;
      margin: 0;
    }

    &__list {
      margin-top: 0;
      text-align: center;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 7s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 7s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

.content2 {
  left: 47.5%;

  &__container {
    // padding-right: 50px;
  }
}

.content3 {
  left: 65.5%;

  &__container {
    // padding: 0 50px;
    &__list {
      animation: none;
      padding-left: 0;
      // margin-left: 20px;
    }
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }


  25% {
    transform: translate3d(0, -35%, 0);
  }

  50% {
    transform: translate3d(0, -65%, 0);
  }

  75% {
    transform: translate3d(0, -35%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }


  25% {
    transform: translate3d(0, -35%, 0);
  }

  50% {
    transform: translate3d(0, -65%, 0);
  }

  75% {
    transform: translate3d(0, -35%, 0);
  }
}

@keyframes change {

  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  
  25% {
    transform: translate3d(0, -35%, 0);
  }

  50% {
    transform: translate3d(0, -65%, 0);
  }

  75% {
    transform: translate3d(0, -35%, 0);
  }
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/