@font-face {
  font-family: Mazzard;
  src: url("../assets/fonts/MazzardM-Regular.ttf");
}

@font-face {
  font-family: "Mazzard-Light";
  src: url("../assets/fonts/MazzardH-Light.ttf");
}

@font-face {
  font-family: "Mazzard-Thin";
  src: url("../assets/fonts/MazzardM-Thin.ttf");
}

@font-face {
  font-family: "Mazzard-Extra-Light";
  src: url("../assets/fonts/MazzardH-ExtraLight.woff");
}

@font-face {
  font-family: "MazzardH-Medium";
  src: url("../assets/fonts/MazzardH-Medium.ttf");
}


