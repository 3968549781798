$font-size-xxxl: 3.75vw;
$font-size-xl: 4.03vw;
$font-size-lg: 5vw;
$font-size-sm: 5.6vw;
$font-size-xs: 5.4vw;

$line-height-xxxl: 5.2vw;
$line-height-xl: 5.2vw;
$line-height-lg: 5.8vw;
$line-height-sm: 6.7vw;
$line-height-xs: 6.4vw;


.main {
  position: relative;
  margin-bottom: 184px;
}

.video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.textWrap {
  position: absolute;
  left: 0;
  bottom: 64px;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    bottom: 96px;
  }

  @media screen and (max-width: 1024px) {
    bottom: 56px;
  }

  @media screen and (max-width: 768px) {
    bottom: 72px;
  }

  @media screen and (max-width: 375px) {
    bottom: 128px;
  }

}

.textBlock {
  
  
  &:nth-of-type(1) {
    .text {
      padding-left: 58px;
      @media screen and (max-width: 1440px) {
        padding-left: 2.5vw;
      }
    
      @media screen and (max-width: 1024px) {
        padding-left: 3.1vw;
      }
    
      @media screen and (max-width: 768px) {
        padding-left: 2.1vw;
      }
    
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(4)  {
    .text {
      padding-right: 58px;
      font-style: italic;

      @media screen and (max-width: 1440px) {
        padding-right: 3.1vw;
      }
    
      @media screen and (max-width: 1024px) {
        padding-right: 2.5vw;
      }
    
      @media screen and (max-width: 768px) {
        padding-right: 2.1vw;
      }
    
    }
  }

  &:nth-of-type(3),
  &:nth-of-type(5)  {
    .text {
      padding-left: 58px;
      font-style: italic;

      @media screen and (max-width: 1440px) {
        padding-left: 3.1vw;
      }
    
      @media screen and (max-width: 1024px) {
        padding-left: 2.5vw;
      }
    
      @media screen and (max-width: 768px) {
        padding-left: 2.1vw;
      }
    
    }
  }

  // &:nth-of-type(4) {
  //   .text {
  //     padding-left: 20.31vw;

  //     @media screen and (max-width: 1440px) {
  //       padding-left: 16vw;
  //     }
    
  //     @media screen and (max-width: 1024px) {
  //       padding-left: 0;
  //     }
    
  //     @media screen and (max-width: 768px) {
  //       // padding-left: ;
  //     }
    
  //   }
  // }

  // &:nth-of-type(5) {
  //   .text {
  //     padding-right: 29vw;

  //     @media screen and (max-width: 1440px) {
  //       padding-right: 24vw;
  //     }
    
  //     @media screen and (max-width: 1024px) {
  //       padding-right: 0;
  //     }
    
  //     @media screen and (max-width: 768px) {

  //     }

  //   }
  // }

  
}

.text {
  font-size: $font-size-xxxl;
  line-height: $line-height-xxxl;
  font-weight: 300;
  position: relative;
  margin-top: 12px;
  color: #282728;
  text-transform: uppercase;
  
  @media screen and (max-width: 1440px) {
     margin-top: 14px;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    
  }

  @media screen and (max-width: 1024px) {
     margin-top: 8px;
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    
  }

  @media screen and (max-width: 768px) {
     margin-top: 4px;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    
    font-weight: 250;
  }

  @media screen and (max-width: 375px) {
     margin-top: 8px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    
  }
}
