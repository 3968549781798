.aboutWrap {
  padding-top: 172px;
  background: #282728;
    
}

.about {
  position: relative;
  z-index: 3;
  padding-bottom: 460px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 180px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 88px;
  }

  @media screen and (max-width: 525px) {
    padding-bottom: 80px;
  }
}

.circleTurquoise {
  width: 91.6vw;
  height: 91.6vw;
  border-radius: 50%;
  background: #054d5c;
  position: absolute;
  top: -16%;
  left: 0;
  z-index: 2;
  
  @media screen and (max-width: 425px) {
    top: -10%;
  }
}

.aboutTitle {
  max-width: 1053px;
  padding-bottom: 24px;
  transform: translate(0, -30px);
  transition: 1.2s;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    max-width: 851px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 737px;
    padding-bottom: 8px;
  }

  @media screen and (max-width: 768px) {
    max-width: 630px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: 375px) {
    max-width: 100%;
    padding-bottom: 32px;
  }
}

.aboutTitleHidden {
  transition: 0;
  opacity: 0;
}

.aboutTitleVisible {
  opacity: 1;
}


.aboutSubtitle {
  margin-left: auto;
  margin-right: 50px;
  padding-top: 192px;
  padding-bottom: 42vh;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    padding-top: 112px;
    padding-bottom: 172px;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 144px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 96px;
  }

  @media screen and (max-width: 375px) {
    padding-bottom: 160px;
  }
}

.elevator {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 0 64px;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    padding: 16px 0 36px;
  }

  @media screen and (max-width: 525px) {
    flex-direction: column;
    padding: 32px 0 48px;
  }
}
