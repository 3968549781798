.premiumIcons {
  padding: 504px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

 
  @media screen and (max-width: 1440px) {
    padding: 312px 0;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 200px 0;
    overflow-x: scroll;
    padding-left: 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 160px 0 160px 32px;
  }

  @media screen and (max-width: 375px) {
    padding: 144px 16px;
  }
}

.stylish {

}

.uniquelyIcon {
}

/*

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/