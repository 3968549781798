.bubbles {
  position: relative;
  height: auto;
  background: #F6F6F6;
  overflow: hidden;
  min-height: 100vh;
}

.bubblesDescription {
  margin-bottom: 32vh;
}


.bubblesIconWrap {
  position: relative;
}

.bubblesIcon {
  transform-origin: bottom;
  overflow: visible;
  width: 100%;
  position: relative;
  z-index: 2;
  mix-blend-mode: difference;  

  circle {
    transition: 0.9s;
  }
}

.bubblesBg {
  position: absolute;
  bottom: -10px;
  left: -58px;
  display: block;
  width: 100vw;
  height: 50%;
  background: #282728;
}
