.portfolio {
  overflow: hidden;
  height: 250vh;

  @media screen and (max-width: 1024px) {
    overflow: scroll;
    height: auto;
  }

  @media screen and (max-width: 768px) {
  }
  
  @media screen and (max-width: 375px) {}
}

.portfolioDescription {
  font-size: 24px;
  line-height: 28px;
  padding-top: 8.54vw;
  display: block;
  max-width: 535px;
  margin: 0 18.34vw 0 auto;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }


  @media screen and (max-width: 375px) {
  }
}

.portfolioImages {
  height: auto;
  position: relative;
  width: 100%;
  height: 100%;
  
  @media screen and (max-width: 1024px) {
    overflow: auto;
    position: static;
    display: flex;
    margin-bottom: 30px;
    scrollbar-width: none;
    
    &:-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
    }
  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 375px) {}
} 

.circleIcon {
  max-width: 1556px;
  max-height: 1556px;
  position: absolute;
  top: 40px;
  left: 10vw;
  width: 80vw;
  height: auto;
  z-index: -1;

  @media screen and (max-width: 1440px) {
    max-width: 1136px;
    max-height: 1136px;
    top: 70px;
  }
  
  @media screen and (max-width: 1024px) {
    max-width: 960px;
    max-height: 960px;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 704px;
    max-height: 704px;
    top: 56px;
  }

  @media screen and (max-width: 375px) {
    display: none;
  }
}

.portfolioImage1 {
  border: 1px solid #282728;
  height: auto;
  width: 16.20vw;
  position: absolute;
  top: 0;
  left: -17vw;

}

.portfolioImage2 {
  border: 1px solid #282728;
  height: auto;
  width: 19.39vw;
  position: absolute;
  top: 5vh;
  left: 16vw;

}

.portfolioImage3 {
  border: 1px solid #282728;
  height: auto;
  position: absolute;
  top: 45vh;
  left: 39vw;
  z-index: -1;
  width: 21.25vw;
  
}

.portfolioImage4 {
  border: 1px solid #282728;
  height: auto;
  position: absolute;
  width: 18.85vw;
  top: 5vh;
  right: 0vw;

}

.portfolioImage5 {
  border: 1px solid #282728;
  height: auto;
  width: 14.38vw;
  top: 25vh;
  position: absolute;
  left: 3vw;
  top: 45.99vw;
  
}

.portfolioImage6 {
  border: 1px solid #282728;
  width: 20.89vw;
  height: auto;
  position: absolute;
  left: 28vw;
  top: 93vh;
}

.portfolioImage7 {
  border: 1px solid #282728;
  height: auto;
  position: absolute;
  top: 105px;
  top: 65vh;
  right: 10vw;
  width: 15.78vw;
}


.portfolioImgMob {
  // width: 70vw;
  height: 50vw;

  &:not(:last-child) {
    margin-right: 20px;
  }
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/