.premiumTextWrap {
  background: #282728;
  overflow: hidden;
  height: 3145px;

  @media screen and (max-width: 1440px) {
    height: 2000px;
  }
  
  @media screen and (max-width: 1024px) {
    height: 189.36vw;
  }

  @media screen and (max-width: 768px) {
    height: 1385px;
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (max-width: 500px) {
    height: 2301px;
  }
}

.premiumText {
  max-width: 1280px;
  width: 100%;
  padding: 64px 0 440px;
  font-weight: 500;

  @media screen and (max-width: 1440px) {
    // max-width: 950px;
    padding: 56px 0 120px;
  }
  
  @media screen and (max-width: 1024px) {
    max-width: 662px;
    padding: 48px 0 120px;
  }

  @media screen and (max-width: 768px) {
    max-width: 497px;
  }

  @media screen and (max-width: 375px) {
    max-width: 100%;
  }
}

.text {
  font-family: "MazzardH-Medium";
  display: inline-block;
  color: #9A9A9A;
  font-weight: 500;
  font-size: 5.21vw;
  line-height: 6.15vw;
  margin-right: 24px;

  @media screen and (max-width: 1440px) {
    font-size: 5.56vw;
    line-height: 6.60vw;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 6.25vw;
    line-height: 6.25vw;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 47px;
  }

  @media screen and (max-width: 375px) {}

  // &:nth-child(12) {
  //   margin-right: 17.4vw;
  // }

  // &:nth-child(20),
  // &:nth-child(26),
  // &:nth-child(33),
  // &:nth-child(34),
  // &:nth-child(38) {
  //   margin-right: 24px;
  // }
  
  // &:nth-child(24) {
  //   margin-right: 15.26vw;
  // }

  // &:nth-child(37) {
  //   margin-right: 200px;
  // }
}

.premiumImages {
  display: flex;

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.elevatorImage {
  width: 50%;

  &:nth-child(2n) {
    margin-left: 24px;

    @media screen and (max-width: 1440px) {
      margin-left: 16px;
    }
    
    @media screen and (max-width: 1024px) {}
  
    @media screen and (max-width: 768px) {}
  
    @media screen and (max-width: 500px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.textImages {
  font-weight: 400;
  font-size: 40px;
  line-height: 47px;
  padding: 56px 0 136px;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 38px;
    padding: 40px 0 75px;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 28px;
    line-height: 33px;
    padding: 32px 0 72px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    padding: 32px 0 64px;
  }

  @media screen and (max-width: 375px) {
    padding: 32px 0 56px;
  }
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/