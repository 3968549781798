.history {
  overflow: hidden;
}

.historyWrap {
  position: relative;
  padding-top: 450px;

  @media screen and (max-width: 1440px) {
    padding-top: 350px;
  }
  
  @media screen and (max-width: 1024px) {
    padding-left: 32px;
    padding-top: 236px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 140px;
  }

  @media screen and (max-width: 375px) {}
}

.historyImgWrap {
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;

  @media screen and (max-width: 1024px) {
    display: block;
  }
  
  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}

.imgBlock {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    justify-content: flex-start;
    width: unset;
    height: max-content;
    padding-bottom: 216px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 112px;
  }
}

.imgDescription {
  max-width: 600px;
  font-weight: 400;
  font-size: 40px;
  line-height: 47px;
  
}

.subtitle {
  max-width: 500px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.imgBlockWrap {
  display: flex;
  flex-direction: column;
  

  &:not(:last-child) {
    margin-right: 16px;
  }

  img {
    display: block;
    margin-top: 56px;
    margin-bottom: 24px;
    width: 500px;
    height: 722px;

    @media screen and (max-width: 768px) {
      width: 80vw;
      height: 666px;
      height: auto;
    }
  }

  span {
    font-size: 24px;
    line-height: 28px;
  }
}

/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/