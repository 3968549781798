.footerInfo {
  display: flex;
  max-width: 892px;
  margin-left: auto;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 60px;
  padding-right: 16.73vw;

  @media screen and (max-width: 1440px) {
    padding-top: 80px;
    padding-bottom: 60px;
    padding-right: 225px;
    max-width: 665px;
  }
  
  @media screen and (max-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 40px;
    padding-right: 115px;
    max-width: 491px;
  }

  @media screen and (max-width: 768px) {
    padding-right: 0;
  }

  @media screen and (max-width: 418px) {
    padding-top: 55px;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }
}

.locations {
}

.location {
  display: flex;
  flex-direction: column;
  width: 204px;

  &:nth-child(1) {
    // padding-bottom: 32px;
  }

  @media screen and (max-width: 418px) {
    &:nth-child(2) {
      padding-bottom: 40px;
    }    
  }
}

.locationLabel {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.locationAddress {
  font-size: 16px;
  line-height: 19px;
}

.navLink {
  display: flex;
  flex-direction: column;
  padding-left: 252px;

  @media screen and (max-width: 1440px) {
    padding-left: 120px;
  }
  
  @media screen and (max-width: 1024px) {
    padding-left: 56px;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 418px) {
    padding-left: 0;
  }
}

.link {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  position: relative;
  width: max-content;
  padding-bottom: 5px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #F6F6F6;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}




/* 

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 375px) {

}

*/