.premiumIcons {
  padding: 504px 0;

  @media screen and (max-width: 1440px) {
    padding: 312px 0;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 200px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 160px 0;
  }

  @media screen and (max-width: 375px) {
    padding: 144px 0;
  }
}

.sliderWrap {
  overflow-x: hidden;
  height: 300vh;
  position: relative;
  @media screen and (max-width: 768px) {
    height: auto;
  }
}

.slider {
  width: 100vw;
  height: 100vh;
  left: 0;
  bottom: 0;
  scrollbar-width: none;

  > div:last-child:-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}

/*

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/