.about {
  padding-top: 152px;
  padding-bottom: 40px;
  background: #282728;

  @media screen and (max-width: 1440px) {
    padding-top: 120px;
  }
  
  @media screen and (max-width: 1024px) {
    padding-top: 104px;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 375px) {
  }
}

.aboutTextWrap {
  display: flex;
  justify-content: space-between;
  padding-top: 38px;

  @media screen and (max-width: 1440px) {
    padding-top: 32px;
  }
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 375px) {
    padding-top: 24px;
  }
}

.aboutTitle {
  flex-basis: 457px;

  @media screen and (max-width: 1440px) {
    flex-basis: 353px;
  }
  @media screen and (max-width: 1060px) {
    flex-basis: 283px;
  }
  
  @media screen and (max-width: 1024px) {
    flex-basis: 316px;
  }

  @media screen and (max-width: 768px) {
    flex-basis: 0;
    max-width: 35vw;;
    padding-bottom: 88px;
  }

  @media screen and (max-width: 375px) {  
    max-width: 178px;
  }
}

.aboutText {
  flex-basis: 740px;

  @media screen and (max-width: 1440px) {
    flex-basis: 550px;
  }
  
  @media screen and (max-width: 1024px) {
    flex-basis: 500px;
  }

}

.aboutSubtitle {
  padding-bottom: 80px;

  @media screen and (max-width: 1440px) {
    padding-bottom: 64px;
  }
}

.aboutDescription {
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  padding-top: 40px;

  &:nth-child(2n) {
    padding-top: 32px;
  }
  
  @media screen and (max-width: 1024px) {
    padding-top: 32px;

    &:nth-child(2n) {
      padding-top: 24px;
    }
  }

  @media screen and (max-width: 375px) {
    padding-top: 24px;
  }
}