
.headerWrap {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(154, 154, 154, 0.7);
  backdrop-filter: blur(10px);
  z-index: 99999;
  border-bottom: 1px solid #F6F6F6;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: max-content;
  padding: 26px 58px 18px 0;
  height: 100%;
  border-right: 1px solid #F6F6F6;

  @media screen and (max-width: 1440px) {
    padding: 24px 32px 16px 0;
  }
}

.headerLink {
  display: flex;
}

.linkWrap {
  padding: 24px 16px;
  border-left: 1px solid #F6F6F6; 
  &:nth-child(2) {
    padding-right: 0;
    .link {
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
}

.link {
  font-family: Mazzard, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0 16px;
  position: relative;
  padding-bottom: 5px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #F6F6F6;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }

  &:hover {
    &::after {
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}
