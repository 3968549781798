.qualityWorkWrap {
  overflow: hidden;
}

.qualityWork {
  height: 80vh;
  position: relative;

}

.circle {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1024px;
  height: 1024px;
  border-radius: 50%;
  background: #054D5C;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 375px) {}
}

.qualityTextWrap {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.qualityText {
  font-weight: 300;
  font-size: 120px;
  line-height: 142px;
  text-transform: uppercase;
  user-select: none;
  display: flex;

  &:first-child, &:nth-child(7) {
    font-style: italic;
  }

  @media screen and (max-width: 1440px) {
    font-size: 58px;
    line-height: 69px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 59px;
  }

  @media screen and (max-width: 768px) {
    font-size: 43px;
    line-height: 51px;
  }

  @media screen and (max-width: 580px) {
    font-size: 20px;
    line-height: 24px;
  }
}



/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/