.statistics {
  background: #054D5C;
  padding: 152px 0;

  @media screen and (max-width: 1440px) {
    padding: 120px 0;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 64px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 56px 0;
  }

  @media screen and (max-width: 375px) {
    padding: 64px 0;
  }
}

.statisticsBlockWrap {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 375px) {
    
  }
}


.statisticsBlock {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 72px 46px 106px 40px;
  position: relative;
  border: 1px solid #F6F6F6;

  @media screen and (max-width: 1440px) {
    padding: 40px 10px 72px 32px;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 32px 24px 64px;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 16px 40px;
    width: 100%;
  }

  @media screen and (max-width: 375px) {
  }
}

.blockTitle {
  font-weight: 300;
  font-size: 4.38vw;
  line-height: 5.16vw;
  padding-bottom: 46px;

  @media screen and (max-width: 1440px) {
    font-size: 4.58vw;
    line-height: 5.42vw;
    padding-bottom: 40px;
  }
  
  @media screen and (max-width: 1024px) {
    
    font-size: 4.30vw;
    line-height: 5.08vw;
    padding-bottom: 35px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
    height: auto;
    padding-bottom: 28px;
  }

  @media screen and (max-width: 375px) {
    
  }
}

.blockDescription {
  font-size: 1.3vw;
  line-height: 1.5vw;

  @media screen and (max-width: 1440px) {
    font-size: 1.4vw;
    line-height: 1.7vw;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 2vw;
    line-height: 2.4vw;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.1vw;
    line-height: 2.6vw;
  }

  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: 19px;
  }
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/