.portfolio {
  padding: 205px 0;
  background: #282728;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    padding: 92px 0 152px;
  }
  
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 418px) {
    padding: 64px 0 32px;
  }
}

.portfolioText {
  position: relative;
  margin-left: 13vw;
  max-width: 947px;
  margin-bottom: 46vw;

  @media screen and (max-width: 1440px) {
    max-width: 686px;
    margin-left: 8.41vw;
  }
  
  @media screen and (max-width: 1250px) {
    margin-left: 45px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 505px;
  }

  @media screen and (max-width: 920px) {
    margin-left: 0;
    max-width: 419px;
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 375px) {

  }
}

.portfolioDescription { 
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (max-width: 920px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (max-width: 375px) {}
}

.previewImg1 {
  position: absolute;
  right: -29vw;
  top: 60px;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    right: -36vw;
    width: 362px;
    height: 496px;
  }
  
  @media screen and (max-width: 1250px) {
    width: 284px;
    height: 389px;
    top: 8%;
    right: -29vw;
  }

  @media screen and (max-width: 1024px) {
    top: 0;
    right: -33vw;
  }

  @media screen and (max-width: 920px) {
    top: 10%;
    right: -40vw;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

}

.previewImg2 {
  position: absolute;
  right: -16vw;
  top: 110%;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    right: -23vw;
    width: 553px;
    height: 368px;
  }

  @media screen and (max-width: 1250px) {
    width: 443px;
    height: 296px;
    right: -16vw;
  }
  
  @media screen and (max-width: 1024px) {
    right: -19vw;
  }

  @media screen and (max-width: 920px) {
    right: -29vw;
    width: 378px;
    height: 252px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.portfolioImages {
  display: grid;
  gap: 5vw;
  grid-auto-flow: dense;
  grid-template-columns: repeat(3,minmax(60px,1fr));
}

.portfolioImage {
  object-fit: cover;
  width: 100%;
}

.tall {
  grid-row-end: span 2;
}

.wide {
  grid-column-end: span 2;
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/