.historySubtitleWrap {
  max-width: 500px;
  width: 100%;
  top: 0;
  height: 3100px;
  
  @media screen and (max-width: 1440px) {
    height: 2194px;
    max-width: 400px;
  }
  
  @media screen and (max-width: 1024px) {
    max-width: 300px;
  }

  @media screen and (max-width: 768px) {
    max-width: 500px;
  }

  @media screen and (max-width: 375px) {
    max-width: 343px;
  }

}

.historySubtitle {
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  max-width: 500px;
  width: 100%;
  left: 58px;
  bottom: 38vh;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 24px;
    max-width: 400px;
    left: 32px;
    bottom: 29vh;
  }
  
  @media screen and (max-width: 1024px) {
    max-width: 300px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
    max-width: 500px;
    left: 16px;
  }

  @media screen and (max-width: 375px) {
    max-width: 343px;
  }
}