.preloader {
  width: 100vw;
  height: 100vh;
  background: #F6F6F6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: #F6F6F6;
}

.charsWrap {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}

.preloaderText {
  font-weight: 275;
  font-family: "Mazzard-Extra-Light";
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  
  &:nth-child(3) {
    margin-right: 12px;
  }
}