.imgDescription {
  font-family: Mazzard;
  display: block;
  max-width: 600px;
  font-weight: 400;
  font-size: 2.08vw;
  line-height: 2.45vw;
  padding-left: 50px;
  height: 552px;

  &:not(:last-child) {
    margin-bottom: 184px;
  }

  @media screen and (max-width: 1440px) {
    max-width: 492px;
    padding-left: 32px;
    font-size: 2.22vw;
    line-height: 2.57vw;
    height: 552px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2.75vw;
    line-height: 3.25vw;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}


/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/


.elevator {
  width: 511px;
  
  &:not(:last-child) {
    margin-bottom: 272px;
  }

  &:last-child {
    margin-bottom: 211px;
  }
  
  @media screen and (max-width: 1440px) {
    width: 368px;

    &:not(:last-child) {
      margin-bottom: 184px;
    }

    &:last-child {
      margin-bottom: 158px;
    }
  }

  @media screen and (max-width: 1024px) {

    &:not(:last-child) {
      margin-bottom: 120px;
    }

    &:last-child {
      margin-bottom: 143px;
    }
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}
