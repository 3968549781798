.subtitle {
  display: block;
  font-weight: 400;
  font-size: 34px;
  line-height: 41px;
  max-width: 880px;
  
  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 38px;
    max-width: 665px;
  }
  
  @media screen and (max-width: 1024px) {
    max-width: 491px;
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    max-width: 469px;
  }

  @media screen and (max-width: 375px) {
    max-width: 326px;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.title {
  font-weight: 400;
  font-size: 20.42vw;
  line-height: 24.12vw;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 4.16667%;

  @media screen and (max-width: 1440px) {
    font-size: 19.9vw;
    line-height: 23.5vw;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 19.73vw;
    line-height: 23.4vw;
  }

  @media screen and (max-width: 768px) {
    font-size: 19.9vw;
    line-height: 180px;
  }

  @media screen and (max-width: 375px) {
    font-size: 73px;
    line-height: 86px;
  }
}

.aboutTitle {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 400;
  font-size: 19.9vw;
  line-height: 23.5vw;
  z-index: 999;
}

.h3 {
  font-family: Mazzard;
  font-weight: 400;
  font-size: 3.75vw;
  line-height: 4.43vw;
  text-transform: uppercase;
  color: #F6F6F6;

  @media screen and (max-width: 1440px) {
    font-size: 4.03vw;
    line-height: 4.8vw;
  }

  @media screen and (max-width: 1024px) {
    font-size: 4.89vw;
    line-height: 5.77vw;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 5.6vw;
    line-height: 6.65vw;
  }

  @media screen and (max-width: 375px) {
    font-size: 28px;
    line-height: 33px;
  }
}

.elevator-name {
  font-size: 40px;
  line-height: 47px;
  opacity: 0;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media screen and (max-width: 525px) {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 16px;
  }
}

.elevator-description {
  font-size: 24px;
  line-height: 28px;
  max-width: 892px;
  opacity: 0;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 28px;
    max-width: 665px;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
    max-width: 491px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
    max-width: 343px;
  }

  @media screen and (max-width: 525px) {
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
  }
}

.specialTitle {
  font-style: normal;
  font-weight: 300;
  font-size: 72px;
  line-height: 85px;
  text-transform: uppercase;
  padding-bottom: 24px;

  @media screen and (max-width: 1440px) {
    font-size: 58px;
    line-height: 69px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 59px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 43px;
    line-height: 51px;
  }

  @media screen and (max-width: 375px) {
    font-size: 28px;
    line-height: 33px;
  }
}

/*

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 375px) {

}

*/