body {
  --color-white: #F6F6F6;
  --color-black: #282728;
}

$color-white: #F6F6F6;
$color-black: #282728;
$color-grey: #9A9A9A;

.color-white {
  color: $color-white;
}

.color-black {
  color: $color-black
}

.color-grey {
  color: $color-grey;
}

.mx {
  margin: 0 3.75vw 0 10.41vw;
}