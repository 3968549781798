
.characteristics {
  padding: 225px 0 450px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1440px) {
    padding: 156px 0 312px;
  }
  
  @media screen and (max-width: 1024px) {
    padding: 56px 0 112px;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0 80px;
  }

  @media screen and (max-width: 375px) {}
}

.characteristicsText {
  display: flex;
  flex-direction: column;
  max-width: 627px;
  text-align: right;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    text-align: left;
  }
}


.characteristicsDescription {
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 72px;

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {
    padding-bottom: 32px;
  }
}

.specifications {
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1440px) {}
  
  @media screen and (max-width: 1024px) {
    justify-content: space-between;
    padding-bottom: 72px;
    width: 306px;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {
    padding-bottom: 56px;
    width: 295px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 120px;
  }
}

.loadedCircle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: rotate(-90deg);

  circle {
    transition: 0.9s;
  }
}

.circle {
  width: 172px;
  height: 172px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #D9D9D9;
  margin-bottom: 12px;

  @media screen and (max-width: 1024px) {
    width: 120px;
    height: 120px;
  }
}

.circleText {
  text-align: center;
  font-family: Mazzard;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

  @media screen and (max-width: 1024px) {
    font-size: 2.73vw;
    line-height: 3.22vw;
  }
}

.characteristicsImageWrap {
  margin-right: 113px;

  @media screen and (max-width: 1440px) {
    margin-right: 72px;
  }
  
  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}

.characteristicsImage {
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 1440px) {
    max-width: 662px;
    height: 396px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 375px) {}
}

/* 

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 375px) {}

*/