.typeElevator {
  overflow: hidden;
  margin: 0 auto;
  height: 100vh;
  background: #282728;
  position: relative;
}

.typeElevatorMain {
  width: 20vw;
  height: 100vh;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}

.typeElevatorMainText {
  font-family: "Mazzard-Light";
  font-weight: 300;
  font-size: 72px;
  line-height: 85px;
  text-align: center;
  text-transform: uppercase;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  
  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 59px;
  }

  @media screen and (max-width: 768px) {
    font-size: 43px;
    line-height: 51px;
  }

  @media screen and (max-width: 520px) {
    font-size: 20px;
    line-height: 24px;
  }
}
